import { graphql } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Hero from "../components/Hero"
import Carousel from "../components/LogoCarousel"
import { selectionsContext } from "../components/Layout"
import { State } from "../global"
import Primary from "../components/ProgressTab/Primary"
import { useSteps } from "../hooks/useSteps"
import Role from "../components/Cards/Role"
import copy from "../data/career.yml"
import tw from "twin.macro"
import Seo from "../components/SEO"
import { authCheck } from "../helpers"
import ProgressTab from "../components/ProgressTab"



const RolePage: React.FC<{ data: any, pageContext: any}> = ({ data, pageContext }) => {
  ;<pre>{JSON.stringify(data, null, 4)}</pre>

  const { state }: State = useContext(selectionsContext)
  const progress = useSteps("1")
  const [sponsors, setSponsors] = useState(null)  
  const careerId = data.allOption1.nodes.map((option: any )=> (option.option1Value)).indexOf(pageContext.option1selection)
  const industryId = data.allOption1.nodes[careerId]?.children.map((option: any) => (option.option2Value)).indexOf(pageContext.option2selection)
  authCheck()
  
  useEffect(() => {
        fetch(`${process.env.GATSBY_API_ROOT_URL}/event-configs/${process.env.GATSBY_EVENT_CONFIG_ID}/sponsors?Option1=${careerId+1}&Option2=${industryId+1}`)
        .then(response => response.json())
        .then(data => setSponsors(data))
    
    }, [])

  return (
    <>
      <Seo title="Choose Role" />
      <Primary data={progress} kiosk={0} active={false}  />
      <Hero />
      <Role
        data={data.allOption1.nodes}
        messages={data.allOption1ScreenMessage.nodes}
        pageContext= {pageContext}
        careerId={careerId}
        industryId={industryId}
      />
       <div css={[tw`lg:pt-28`]}>
        <Carousel data={sponsors} header={copy.carousel_header}/>
      </div>
      <ProgressTab.Mobile data={progress} kiosk={0}/>

    </>
  )
}

export const query = graphql`
  {
    allOption1 {
      nodes {
        option1Value
        children {
          ... on Option2 {
            option2Value
            children {
              ... on Option3 {
                option3Value
              }
            }
          }
        }
      }
    }
    allOption1ScreenMessage {
      nodes {
        id
        screenMessage
        options {
          screenMessage
          icon
          label
          options {
            screenMessage
            icon
            label
            options {
              icon
              label
            }
          }
        }
      }
    }
    allCardsYaml {
      nodes {
        card
        headline
        subheading
        items {
          label
          url
        }
      }
    }

    allSponsor {
    nodes {
      id
      logo
      name
      sponsorID
      email
      booth
    }
  }
  }
`
export default RolePage
