import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { CardProps } from "./Cards.d"
import { selectionsContext } from "../Layout"
import { Link } from "gatsby"
import { sluggify } from "../../helpers"
import Card, { CardRow } from "./Card"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"


const Role: React.FC<CardProps> = ({
  data,
  messages,
  careerId,
  pageContext,
}) => {
  const { dispatch }: any = useContext(selectionsContext)
  const [optionSelected, setOptionSelected] = useState(false)
  const industryId = data[careerId]?.children
    .map(option => option.option2Value)
    .indexOf(pageContext.option2selection)
  const option1Choice = careerId
  const option2Choice = industryId
  const updateRole = (selection: any, index: number) => {
    dispatch({ type: "UPDATE_ROLE", role: selection, id: index })
  }

  const screenMessage3 = messages[0]?.options
    .filter(
      (option: { label: any }) => option.label == pageContext.option1selection
    )[0]
    ?.options.filter(
      (option: { label: any }) => option.label == pageContext.option2selection
    )[0]

  return (
    <div
      css={[
        tw`xl:container mx-auto w-full px-0 lg:px-24 2xl:px-44 justify-center justify-items-center bg-transparent absolute top-44 md:top-64 lg:top-96 2xl:top-96 right-0 left-0 xl:z-30`,
      ]}
    >
      <div css={[tw`text-center justify-center pb-3 lg:w-10/12 mx-auto`]}>
        <h4 css={[tw`text-white pb-5`]}>Step 3</h4>
        <h2 css={[tw`text-white leading-snug pb-5`]}>
          {screenMessage3?.screenMessage}
        </h2>
      </div>
      <div
        css={[
          tw`relative left-0 opacity-100 transition-all ease-out duration-700`,
          optionSelected && tw`opacity-0 -left-32`,
        ]}
      >
        <CardRow>
          {data[option1Choice]?.children[option2Choice].children.map(
            (item: any, i: number) => (
              <Link
                key={item.option3Value}
                to={`/career-results/${sluggify(
                  pageContext.option1selection
                )}/${sluggify(pageContext.option2selection)}/${sluggify(
                  item.option3Value
                )}`}
              >
                <Card
                  i={i}
                  item={item}
                  title={item.option3Value}
                  image={
                    messages[0]?.options
                      .filter(
                        (option: { label: any }) =>
                          option.label == pageContext.option1selection
                      )[0]
                      ?.options.filter(
                        (option: { label: any }) =>
                          option.label == pageContext.option2selection
                      )[0]?.options.filter(
                        (option: {label: any}) => option.label == item.option3Value)[0].icon
                  }
                  onClick={() => {
                    setOptionSelected(true)
                    updateRole(item.option3Value, i)
                    let selectedCareer = option1Choice + 1
                    let selectedIndustry = option2Choice + 1
                    let selectedRole = i + 1
                    AnalyticsEventsClient.event({
                      eventType: "click",
                      eventName: "click_option " + selectedCareer + "." + selectedIndustry + "." + selectedRole,
                      eventMessage: "User selected " + item.option3Value,
                      visitorID: localStorage.getItem("visitorID")

                    })
                  }}
                />
              </Link>
            )
          )}
        </CardRow>

      </div>
    </div>
  )
}

export default Role
